/* Team Members
================================================== */
.internal {
  main {
    .column.team-page {
      padding: 50px 10px 0;
      @include bp(large) {
        padding: 120px 40px 0;
      }
      @include bp(xxlarge) {
        padding: 120px 100px 0;
      }
    }
  }
}
h2.position {
  span {
    color: $light-grey;
    font-size: 0.8em;
  }
  color: $white;
}
.team-members {
  width: 100%;
  float: left;
  margin-bottom: 80px;
  ul {
    margin: 0;
  }
  li {
    width: 50%;
    float: left;
    padding: 20px;
    list-style: none;
    text-align: center;
    @include bp(large) {
      width: 25%;
    }
    @include bp(xxlarge) {
      width: 14.25%;
    }
    &:hover {
      .team-info {
        background-color: rgb(121, 131, 141);
      }
    }
  }
  a {
    position: relative;
    width: 100%;
    float: left;
    height: 100%;
    text-decoration: none !important;
  }
  p {
    color: $white;
    &.title {
      font-size: 0.9em;
      color: $off-white !important;
    }
    &.position {
      margin-bottom: 20px;
      font-size: 1.1em;
      text-transform: uppercase;
    }
    &.sub-title {
      font-size: 0.8em;
      color: $light-steel-blue !important;
      text-transform: uppercase;
    }
  }
  .team-image {
    width: 100%;
    height: 220px;
    float: left;
    margin-bottom: 15px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }
  .team-info {
    @include transition(background-color 0.25s ease-in-out);
    padding: 20px 10px 10px;
    background-color: rgb(121, 131, 141);
    background-color: rgba(121, 131, 141, 0.6);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#79838d, endColorstr=#79838d);
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#79838d, endColorstr=#79838d)";
  }
}

.patents-team-text {
  margin-top: 40px;
}

.membersWrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .membersWrap--image {
    width: 400px;
    display: flex;
    flex-direction: column;

    @include bp_max(large) {
      width: 100%;
    }
  }

  .membersWrap--container {
    width: calc(100% - 450px);

    @include bp_max(large) {
      width: 100%;
    }
  }
}
