/* Newsletter Signup
================================================== */
.newsletter-section {
  width: 100%;
  float: left;
  padding: 10%;
  background: url("#{$template-image-path}/newsletter-bg.jpg") no-repeat 50% 50%;
  background-size: cover;
  text-align: center;
  @include bp(xxlarge) {
    width: 65%;
  }
  .v-align {
    @include bp(large) {
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
  h2 {
    position: relative;
    margin-bottom: 40px;
    font-size: 1.5em;
    color: $white;
    &:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: -15px;
      left: 0;
      width: 30%;
      height: 2px;
      margin: auto;
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }
  .title {
    width: 100%;
    margin: 0 auto 40px auto;
    font-family: $secondary-font;
    font-size: 1.6em;
    font-weight: 400;
    line-height: 140%;
    color: $white;
    @include bp(large) {
      font-size: 2.4em;
    }
    @include bp(xxlarge) {
      width: 70%;
      font-size: 3em;
    }
  }
  #form12 {
    .wdform_page {
      background: transparent;
    }
    .wdform-label-section {
      display: none;
    }
    .wdform_section {
      width: 100%;
    }
    .wdform_column {
      float: none;
    }
    .wdform-field,
    .wdform-element-section {
      width: 100% !important;
      float: left;
      text-align: center;
    }
    #wdform_1_element12 {
      position: relative;
      width: 100%;
      height: auto;
      background: transparent;
      border: none;
      font-size: 1.2em;
      font-weight: 200;
      font-style: normal;
      color: $blue;
      text-transform: uppercase;
      text-align: center;
      font-family: $tertiary-font;
      -webkit-backface-visibility: hidden;
      -webkit-appearance: none;
      outline: none;
      @include bp(smedium) {
        font-size: 1.6em;
      }
      @include bp(large) {
        font-size: 2em;
      }
      @include bp(xxlarge) {
        font-size: 2.2em;
      }
      &::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: $white;
      }
      &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: $white;
        opacity: 1;
      }
      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: $white;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $white;
      }
      &.input_active {
        &::-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          color: $blue;
        }
        &:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: $blue;
        }
        &::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: $blue;
        }
        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $blue;
        }
      }
    }
    div[type="type_submitter_mail"] {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        right: 0;
        bottom: -5px;
        left: 0;
        width: 80%;
        height: 2px;
        margin: auto;
        background: linear-gradient(
          to right,
          rgba(0, 0, 0, 0) 0%,
          rgba(175, 109, 17, 1) 50%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }
    .wdform-field,
    div[type="type_submit_reset"] .wdform-element-section {
      @extend %inline-block;
    }
    .button-submit {
      background: transparent;
      font-size: 1em;
      p {
        color: $white;
      }
      .circle-icon {
        background-color: $light-gold;
      }
      &:hover {
        .circle-icon {
          background-color: $secondary-colour;
        }
      }
      i {
        color: $slate-grey;
      }
    }
  }
}
.newsletter-form {
  .com-baforms-wrapper {
    .ba-field-container input[type="email"] {
      position: relative;
      font-size: 1.4rem !important;
      font-weight: 200;
      font-style: normal;
      color: $white !important;
      text-transform: uppercase;
      text-align: center;
      font-family: $tertiary-font;
      background: transparent !important;
      -webkit-backface-visibility: hidden;
      -webkit-appearance: none;
    }
  }
}
