/* Sidebar
================================================== */
.sidebar {
  width: 100%;
  float: left;
  overflow: hidden;
  @include bp(xxlarge) {
    width: 30%;
  }
  @include bp(xxlarge) {
    width: 20%;
  }
  .moduletable {
    padding: 10%;
    @include bp(smedium) {
      padding: 5%;
    }
    @include bp(xxlarge) {
      padding: 10%;
    }
  }
}

.clients {
  @include gradient($primary-colour, $off-black);
  height: 100%;
  min-height: 100%;
  //min-height: 70%;

  text-align: center;
  ul,
  li {
    width: 100%;
    float: left;
    margin: 0;
    text-align: center;
  }
  ul {
    height: 75% !important;
    @include bp_max(xxlarge) {
      min-height: 400px;
    }
    margin: 20px 0 60px 0;
  }
  li {
    padding: 20px 0;
  }
  img {
    margin: 0 auto;
    display: block;
    height: 60px;
  }
  p {
    color: $white;
    font-size: 1.1em;
  }
  .small-button {
    .icon {
      i {
        color: $grey;
        @include transition(color 400ms ease-in-out);
      }
    }
    p {
      margin-left: 0;
      color: $grey;
    }
    &:hover {
      .icon {
        i {
          color: $secondary-colour;
        }
      }
      p {
        color: $secondary-colour;
      }
    }
  }
}

.twitter-feed {
  @include gradient($light-slate-grey, $dim-grey);
  min-height: 30%;

  text-align: center;
  iframe#twitter-widget-0 {
    width: 100% !important;
  }
  .small-button {
    .icon {
      background-color: transparent;
      i {
        color: $lavender;
        @include transition(color 400ms ease-in-out);
      }
    }
    p {
      margin-left: 0;
      color: $lavender;
    }
    &:hover {
      .icon {
        i {
          color: $sky-blue;
        }
      }
      p {
        color: $sky-blue;
      }
    }
  }
}
