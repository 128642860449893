/* Header
================================================== */
header {
  position: relative;
  z-index: 10;
  width: 100%;
  float: left;
  .internal & {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.header-logo {
  position: relative;
  p {
    margin: 0;
  }
  img {
    width: 370px;
    @include bp_max(large) {
      width: 100%;
    }
  }
  a {
    display: block;
  }
  &.patents-logo {
    img {
      width: 312px;
    }
  }
}

.header-left {
  float: left;
  @include bp(smedium) {
    margin-right: 10px;
  }
}
.header-right {
  float: right;
  @include bp(smedium) {
    margin-left: 10px;
  }
}

.intro {
  .social-icons {
    width: 80px;
    float: right;
    display: none;
    clear: right;
    @include bp(large) {
      display: block;
    }
    a {
      display: block;
      padding: 10px;
      text-align: center;
      i {
        font-size: 1.4em;
        color: $white;
        @include transition(color 400ms ease-in-out);
        &.fa-google-plus {
          font-size: 1.2em;
        }
      }
      &:hover {
        i {
          color: $light-blue;
        }
      }
    }
  }
}
