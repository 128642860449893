/* Search
================================================== */

// Search v4
.GSearchBlock {
  form {
    width: auto !important;
  }
  .gsearch-table,
  .gsearch-field-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    @include bp_max(large) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .gsearch-cell {
    width: 100%;
    padding: 0;
  }
  .gsearch-table
    .keyword
    .inputbox:not(.bootstrap-select):not(.form-check-input) {
    height: auto !important;
    padding: 6px 4px !important;
    line-height: 100% !important;
    border: none !important;
    margin: 0 !important;
    border-radius: 0 !important;
    max-width: none !important;
    float: left;
    clear: left;
    width: 0px;
    height: 0px;
    background: transparent;
    color: $secondary-colour !important;
    border: none;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: -webkit-transform 400ms
        cubic-bezier(0.785, 0.135, 0.15, 0.86),
      visibility 600ms, opacity 600ms, width 600ms, height 600ms;
    -moz-transition: transform 400ms cubic-bezier(0.785, 0.135, 0.15, 0.86),
      visibility 600ms, opacity 600ms, width 600ms, height 600ms;
    -o-transition: transform 400ms cubic-bezier(0.785, 0.135, 0.15, 0.86),
      visibility 600ms, opacity 600ms, width 600ms, height 600ms;
    transition: transform 400ms cubic-bezier(0.785, 0.135, 0.15, 0.86),
      visibility 600ms, opacity 600ms, width 600ms, height 600ms; /* easeInOutCirc */
    @include transform(translateX(-100%));
    -webkit-backface-visibility: hidden;
    -webkit-appearance: none;
    outline: none;
    @include bp(large) {
      margin-left: 1rem !important;
    }
    @include bp(xxlarge) {
      /*float: none;*/
      clear: none;
      height: 100%;
    }
    //&.active {
    width: 100%;
    height: 100%;
    @include transform(translateX(0));
    visibility: visible;
    opacity: 1;
    @include bp(large) {
      width: 350px;
    }
    //}
    &:focus {
      border: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
  .gsearch-table {
    h3,
    .gsearch-buttons {
      margin: 0;
    }
    h3,
    .keyword .inputbox:not(.bootstrap-select):not(.form-check-input) {
      font-size: 1.2em !important;
      font-weight: 200;
      color: $white;
      text-transform: uppercase;
      font-family: $tertiary-font;
      @include bp(smedium) {
        font-size: 1.6em !important;
      }
      @include bp(large) {
        font-size: 2em !important;
      }
      @include bp(xxlarge) {
        font-size: 2.6em !important;
      }
      @include bp_max(large) {
        width: 100%;
      }
    }
  }
  .gsearch-buttons:before {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    margin: auto;
  }
  .gsearch-buttons .btn {
    position: absolute;
    z-index: 20;
    width: 35px !important;
    height: 35px !important;
    margin: auto;
    padding: 0;
    @include border-radius(50%);
    border: none !important;
    background: transparent !important;
    background-color: transparent !important;
    box-shadow: none !important;
    outline: none !important;
    @include bp(large) {
      width: 50px !important;
      height: 50px !important;
    }
    &:hover {
      background: transparent !important;
      background-color: transparent !important;
    }
  }
}

#gsearch-results {
  min-height: 100%;
  margin: 0;
  padding: 50px 10px;
  @include bp(large) {
    padding: 120px 40px 100px;
  }
  @include bp(xxlarge) {
    padding: 120px 100px 100px;
  }

  img {
    display: none;
  }

  .pagination {
    ul {
      margin-bottom: 1rem;
    }
    li {
      padding: 0.8rem;
    }
  }
}

.tooltip {
  display: none !important;
}
.intro-section,
.sitemap-search,
#search-form {
  .search {
    position: relative;
    width: 100%;
    float: left;
    @include bp(smedium) {
      margin-left: 30px;
    }
  }
  form {
    position: relative;
    float: left;
    opacity: 0;
    width: 100%;
    @include transition(opacity 3000ms ease-in-out);
    &.load,
    &#finder-search {
      opacity: 1;
    }
    &:after {
      content: "";
      position: absolute;
      right: 50px;
      bottom: -20px;
      left: 0;
      height: 1px;
      background-image: -webkit-gradient(
        linear,
        0 0,
        100% 0,
        from($lighter-blue),
        to(transparent)
      );
      background-image: -webkit-linear-gradient(
        left,
        $lighter-blue,
        transparent
      );
      background-image: -moz-linear-gradient(left, $lighter-blue, transparent);
      background-image: -o-linear-gradient(left, $lighter-blue, transparent);
      @include bp(smedium) {
        left: -20px;
      }
    }
  }
  label,
  .search-query {
    font-size: 1.2em;
    font-weight: 200;
    color: $white;
    text-transform: uppercase;
    font-family: $tertiary-font;
    @include bp(smedium) {
      font-size: 1.6em;
    }
    @include bp(large) {
      font-size: 2em;
    }
    @include bp(xxlarge) {
      font-size: 2.6em;
    }
  }
  label {
    float: left;
    margin: 5px 20px 5px 0;
    cursor: text;
  }
  .search-query {
    float: left;
    clear: left;
    width: 0px;
    height: 0px;
    background: transparent;
    color: $secondary-colour !important;
    border: none;
    visibility: hidden;
    opacity: 0;
    margin-right: 10px;
    -webkit-transition: -webkit-transform 400ms
        cubic-bezier(0.785, 0.135, 0.15, 0.86),
      visibility 600ms, opacity 600ms, width 600ms, height 600ms;
    -moz-transition: transform 400ms cubic-bezier(0.785, 0.135, 0.15, 0.86),
      visibility 600ms, opacity 600ms, width 600ms, height 600ms;
    -o-transition: transform 400ms cubic-bezier(0.785, 0.135, 0.15, 0.86),
      visibility 600ms, opacity 600ms, width 600ms, height 600ms;
    transition: transform 400ms cubic-bezier(0.785, 0.135, 0.15, 0.86),
      visibility 600ms, opacity 600ms, width 600ms, height 600ms; /* easeInOutCirc */
    @include transform(translateX(-100%));
    -webkit-backface-visibility: hidden;
    -webkit-appearance: none;
    outline: none;
    @include bp(xxlarge) {
      /*float: none;*/
      clear: none;
      height: 100%;
    }
    &.active {
      width: 200px;
      height: 100%;
      @include transform(translateX(0));
      visibility: visible;
      opacity: 1;
      @include bp(smedium) {
        width: 250px;
      }
      @include bp(large) {
        width: 350px;
      }
    }
    &:focus {
      border: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
}

.sitemap-search {
  width: 100%;
  float: left;
  margin: 40px 0;
  .search {
    form {
      opacity: 1;
    }
    .search-query {
      color: $primary-colour;
    }
  }
}

.search button,
.gsearch-buttons {
  position: relative;
  width: 35px;
  height: 35px;
  //bottom: 5px;
  margin: 0;
  @include border-radius(50%);
  background: $light-gold;
  outline: none;
  @include transition(background 1000ms ease-in-out);
  @include bp(large) {
    width: 50px;
    height: 50px;
  }
  &:before {
    content: "\f002";
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    text-decoration: inherit;
    font-size: 1.1em;
    color: $white;
    @include bp(large) {
      font-size: 1.6em;
    }
  }
  &.highlight,
  &.active,
  &:hover {
    background: darken($secondary-colour, 5%);
  }
}

// Search Results
.search-results {
  margin: 0;
  list-style: none;
  float: left;
  li {
    margin-bottom: 30px;
  }
  span.highlight {
    background-color: $secondary-colour;
  }
  dt {
    font-size: 1.4em;
    margin-bottom: 5px;
  }
  dt,
  dd {
    margin-bottom: 15px;
  }
  .result-category,
  .result-created {
    display: none;
  }
}
.search {
  .btn-toolbar {
    width: 100%;
    float: left;
    margin-bottom: 30px;
  }
  .phrases,
  .only {
    margin-bottom: 30px;
    legend {
      font-size: 1.6em;
    }
    label {
      float: left;
      margin: 10px;
    }
  }
  .searchintro,
  .ordering-box {
    width: 100%;
    float: left;
    clear: left;
  }
  .searchintro {
    margin-bottom: 20px;
  }
  .form-limit {
    margin-bottom: 40px;
  }
  .badge-info {
    font-size: 1.4em;
    color: $tertiary-colour;
  }
  .result-text {
    margin-bottom: 30px;
  }
}

.search #search-searchword,
#finder-search .inputbox {
  margin: 0 5px 0 0;
  padding: 5px;
  color: $primary-colour;
  font-size: 1.3em;
  border: 2px solid $grey;
}
#finder-search .inputbox {
  @extend %inline-block;
}
#finder-search label {
  @include bp(xxlarge) {
    position: relative;
    bottom: 6px;
  }
}

// Search v2
#advancedSearch,
#search-query-explained,
#finder-search a.btn,
#finder-search:after,
.search-results .result-url {
  display: none;
}

.intro-section {
  &--search {
    form,
    .search-query {
      margin-bottom: 0;
    }
    .search-query {
      position: relative;
      bottom: 5px;
    }
  }
}
