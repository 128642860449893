/* Contact Page
================================================== */
.internal main .column.full-height-column {
  &.contact-left,
  &.contact-right {
    @include bp_max(xlarge) {
      width: 100%;
    }
  }
}

.contact-left,
.contact-right {
  background-color: #536883;
  & *,
  a:hover {
    color: $white;
  }
}

.contact-form {
  #form13 {
    width: 100%;
    float: left;
    .wdform_page {
      background-color: transparent;
    }
    .wdform-label {
      @extend %inline-block;
      padding: 2px 5px 10px 0;
      color: $white;
    }
    .wdform-label-section {
      font-size: 1.2em;
      font-family: $primary-font;
    }
    input[type="text"],
    textarea {
      border: none;
      padding: 15px !important;
      font-size: 1em;
      font-family: $primary-font;
    }
    .wdform_column,
    .wdform-field,
    .wdform-label-section,
    .wdform-element-section {
      width: 100% !important;
    }
    .wdform_section,
    .wdform-field {
      display: block;
    }
    .wdform-page-and-images {
      display: block !important;
    }
    div[type="type_submit_reset"] {
      .wdform-label-section {
        display: none !important;
      }
    }
    .button-submit {
      margin: 20px 0 0 0;
      font-size: 1.2em;
    }
  }
}

.internal main .column.full-height-column {
  &.contact-right {
    display: block;
    padding: 0;
    .moduletable {
      //min-height: 45%;
      position: relative;
      @include bp(xxlarge) {
        margin-top: 80px;
      }
    }
    .address {
      width: 100%;
      float: left;
      min-height: 40%;
      ul {
        width: 100%;
        margin: 0;
      }
      li {
        list-style: none;
        padding: 0;
      }
    }
  }
}

.contact-right {
  background-color: #72899b;
  .address {
    p {
      margin-bottom: 5px;
    }
  }
}

#map-canvas {
  width: 100%;
  height: 100%;
  min-height: 400px;
  @include bp(xxlarge) {
    min-height: 300px;
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.contact-map {
  width: 100%;
  height: 100%;
  min-height: 400px;
  p {
    margin: 0;
  }
}
