// Endorsements
.endorsements {
  ul {
    position: relative;
  }
  li {
    width: 100%;
    right: 0;
    margin: 40px 0;
    text-align: left;
    list-style: none;
    display: table !important;
    p {
      font-size: 1.2em;
    }
    .title {
      color: $tertiary-colour;
      font-size: 1em;
      margin-top: 20px;
    }
    .testimonial {
      display: table-cell;
      vertical-align: middle;
      border-left: 15px solid transparent;
    }
    /*.testimonial-image, img {
            width: 100px;
            float: left;
            margin-right: 10px;
        }*/
    .testimonial-image,
    img {
      width: 100px;
      display: table-cell;
      vertical-align: middle;
    }
    img {
      margin: 0 !important;
    }
    &:before {
      content: open-quote open-quote;
      position: absolute;
      //top: 80px;
      top: 45%;
      left: 65px;
      z-index: -1;
      font-family: Georgia, Times, Times New Roman, serif;
      color: $sky-blue;
      font-size: 25em;
      letter-spacing: -20px;
    }
  }
  .testimonial-button {
    width: 100%;
    float: left;
    clear: both;
  }
}

.home-endorsements {
  li {
    margin: auto;
    @include bp(xxlarge) {
      width: 70%;
    }
  }
}

.internal {
  .endorsements {
    @include bp(smedium) {
      li {
        margin: 70px 0;
      }
      .testimonial-image,
      img {
        width: 150px;
      }
    }
  }
}
