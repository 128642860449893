// 1. File Paths
// Usage - e.g. url('#{$image-directory}...')
// --------------
$public-path: "../";
$image-directory: "../images/";
$font-directory: "../fonts/";
$template-image-path: "../../../images/template-files/";

// 2. Width
// --------------
$global-width: 1200px;
$website-width: 1200px;
$sixteen-width: 1600px;

// 3. Fonts
// --------------
$primary-font: "Montserrat", sans-serif;
$secondary-font: "Ubuntu", sans-serif;
$tertiary-font: "montserratultra_light";

// 4. Colours
// --------------
/// Colors used for buttons, callouts, links, etc. There must always be a color called `primary`.
$primary-colour: #111111; // Almost Black
$secondary-colour: #37b5fe; // Blue
$tertiary-colour: #2793b2; // Dark Blue
/// @type Map
$foundation-palette: (
  primary: $primary-colour,
  secondary: $secondary-colour,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
) !default;
$light-grey: #eeeeee !default;
$medium-grey: #cacaca !default;
$dark-grey: #8a8a8a !default;
$black: #0a0a0a !default;
$white: #ffffff !default;
$off-white: #edf0ef !default;

$dim_grey: #5a9abf;

//$light_gold: #d4bb80;
$light_gold: $tertiary-colour;
$dark_gold: #af6d11;
$lighter_blue: #3676b4;
$light_blue: #668aae;
$sky_blue: #87bde5;
$light_steel_blue: #bec6d7;
$blue: #154561;
$light_slate_grey: #5c7280;
//$dim_grey: #526783;
$slate_grey: #728096;
$grey: #c6c6c6;
$midnight_blue: #111b58;
$teal: #004990;
$lavender: #dee9f2;
//$cream: #d0bda2;
$cream: $lavender;
$off-black: #242e38;

$body-background: $primary-colour !default;
$body-font-color: $white !default;

// 5. Base
// -----------
$base-font-family: $primary-font !default;
$base-font-size: 14px !default;
$base-line-height: 160% !default;
$base-font-color: $white !default;
$base-font-weight: 400 !default;
$base-background-color: $primary-colour !default;

$small_font: 0.875em;
$large_font: 2em;

// 6. Headings
// --------------
$base-heading-font-family: $primary-font !default;
$base-heading-line-height: $base-line-height !default;
$base-heading-font-weight: 700 !default;
$base-h1-font-size: 2.6rem !default;
$base-h2-font-size: 2.2rem !default;
$base-h3-font-size: 1.8rem !default;
$base-h4-font-size: 1.6rem !default;
$base-h5-font-size: 1.4rem !default;
$base-h6-font-size: 1.4rem !default;

// 7. Breakpoints
// --------------
//  Mixin scss classes e.g. @include bp(mlarge)
//  & Foundation breakpoint html class names e.g. class="small-6 xlarge-12"
$breakpoints: (
  small: 0,
  smedium: 480px,
  medium: 768px,
  large: 960px,
  xlarge: $global-width,
  xxlarge: 1200px,
);
$breakpoint-classes: (small smedium medium large xlarge xlarge xxlarge);

// 8. The Grid
// -----------
$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-gutter: (
  small: 20px,
  medium: 30px,
);
$grid-column-align-edge: true;
$grid-column-alias: "columns";
$block-grid-max: 8;

$grid-gutter: 16px !default;
$global-font-size: 100% !default;
$global-width: rem-calc(1200) !default;
$global-lineheight: 1.5 !default;

// 9. Global
// --------------
$global-lineheight: 1.5;
$body-safe-padding: false;
$body-antialiased: true;
$global-margin: 1rem;
$global-padding: 1rem;
$global-position: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: 0;
$global-menu-padding: 0.7rem 1rem;
$global-menu-nested-margin: 1rem;
$global-text-direction: ltr;
$global-flexbox: true;
$global-prototype-breakpoints: false;
$global-button-cursor: auto;
$global-color-pick-contrast-tolerance: 0;
$print-transparent-backgrounds: true;
$print-hrefs: true;
$global-left: if($global-text-direction == rtl, right, left);
$global-right: if($global-text-direction == rtl, left, right);

// 10. Transition
// --------------
$global-transition: 400ms ease-in-out;
