.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"],
.com-baforms-wrapper .ba-form-submit-btn,
main #form13 .button-submit,
.newslist .link a,
.item-readmore .btn {
  display: inline-block;
  margin-bottom: 20px;
  padding: 8px 10px;
  background: $secondary_colour;
  border: 0;
  color: $white;
  font-size: 1.1em;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  line-height: normal;
  font-family: $base-font-family;
  text-transform: uppercase;
  text-decoration: none !important;
  @include border-radius(3px);
  @include transition($global-transition);
  &:hover {
    color: $white;
    background: darken($secondary-colour, 5%);
    border: 0;
  }
  &:active {
    border: 0;
  }
  &:visited {
    color: $white;
  }
}

.button {
  &--full-width {
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: center;
  }
  &--secondary {
    background: $secondary-colour;
    &:hover {
      background: darken($secondary-colour, 8%);
    }
  }
  &-wrap {
    &--center {
      text-align: center;
    }
  }
}

/* Fix for odd Mozilla border & padding issues */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
