/* Internal Pages
================================================== */
/*#page_about-pb {
  main {
    background-color: #536883;
    & * {
      color: $white;
    }
  }
}
#page_services {
  main {
    background-color: #f3f3e9;
    & * {
      color: #664615;
    }
  }
}
#page_ip-areas {
  main {
    background-color: #505c85;
    & * {
      color: $white;
    }
  }
}*/

.portrait {
  width: 400px;
}

.internal {
  .header-logo {
    img {
      width: 220px;
      @include bp(smedium) {
        width: 250px;
      }
    }
  }
  .main-menu-button {
    p {
      margin: 22px 0;
    }
  }
  .first-section {
    //&.shrink {
    @include bp(large) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 9;
      width: auto;
      margin: auto;
      padding: 8px 8px 6px 8px;
    }
    //}
  }
  main {
    .column {
      min-height: 100%;
      margin: 0;
      padding: 50px 10px;
      @include bp(large) {
        //padding: 170px 40px 100px;
        padding: 120px 40px 100px;
      }
      @include bp(xxlarge) {
        //padding: 170px 100px 100px;
        padding: 120px 100px 100px;
      }
      &.full-height-column {
        padding: 0 50px;
        @include bp(large) {
          padding: 0 100px;
        }
      }
      &.below-header-inner {
        padding: 50px;
        @include bp(large) {
          padding: 100px;
        }
      }
    }
    .one-half,
    .full-one-half {
      @include bp_max(xlarge) {
        width: 100%;
      }
    }
  }
  .menu-link {
    button {
      margin: 25px 20px 10px 20px;
    }
  }
  .social-icons {
    display: none;
  }
}

.page-slider {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 50%;
  @include bp(xxlarge) {
    display: block;
  }
  .cycle-slide {
    width: 100%;
    z-index: 1 !important;
    margin: 0;
  }
}
.page-slider-column {
  margin-left: 0 !important;
  @include bp(xxlarge) {
    margin-left: 50% !important;
  }
}

/* Clients
================================================== */
.full-client-list {
  width: 100%;
  float: left;
  padding: 50px;
  @include gradient($midnight-blue, $teal);
  @include bp(large) {
    padding: 100px;
  }
  ul {
    margin: 0;
  }
  li {
    position: relative;
    width: 100%;
    height: 200px;
    float: left;
    padding: 30px;
    list-style: none;
    text-align: center;
    @include bp(smedium) {
      width: 50%;
    }
    @include bp(large) {
      width: 33.3%;
      height: 250px;
    }
    @include bp(xxlarge) {
      width: 25%;
      height: 300px;
    }
    @include bp(xxlarge) {
      width: 20%;
    }
    a {
      text-decoration: none;
    }
  }
  span {
    display: block;
    text-transform: uppercase;
    font-size: 1.8em;
    font-weight: bold;
    @extend %va;
  }

  img {
    @extend %va;
    max-width: 100%;
  }
}

/* Accordion
================================================== */
.accordion-toggle {
  cursor: pointer;
  @include transition(color 400ms ease-in-out);
  &:hover {
    color: $secondary-colour;
  }
}

/* News
================================================== */
.aidanews2 {
  .aidanews2_art {
    width: 100%;
    float: left;
    margin-bottom: 10px;
  }
  .aidanews2_positions > div {
    margin-bottom: 5px;
  }
  .even {
    background-color: transparent !important;
  }
  .aidanews2_line {
    display: none;
  }
  .aidanews2_mainC {
    overflow: visible;
  }
}
main {
  .paginationstyle,
  .items_pagination {
    a {
      margin: 0 3px;
      padding: 3px 6px;
      border: none;
      color: $white;
      background-color: $secondary-colour;
      text-decoration: none;
    }
    a:hover,
    li.active a {
      background-color: darken($secondary-colour, 5%);
    }
  }
  .sharethis {
    margin: 40px 0 20px;
  }
  .stButton .stFb,
  .stButton .stTwbutton,
  .stButton .stMainServices {
    height: auto;
  }
}

.newslist {
  li {
    margin-bottom: 30px !important;
  }
  .newstitle {
    margin-bottom: 10px !important;
    a {
      font-weight: bold;
      text-decoration: none;
    }
    span {
      font-family: $primary-font;
    }
  }
  .newsintro {
    line-height: $base-line-height;
    margin: 0 0 10px 0;
  }
}

main {
  .latestnews-item {
    .newshead {
      width: 100%;
      .calendar {
        position: static;
        width: 100% !important;
        max-width: none !important;
        background: transparent;
        border: none;
        font-family: $primary-font !important;
        text-align: left !important;
        span,
        .position1 {
          display: inline-block !important;
          margin: 0 2px 0 0 !important;
          font-size: 0.9em !important;
          letter-spacing: 0 !important;
          text-indent: 0 !important;
          line-height: 100% !important;
          font-weight: 200 !important;
        }
      }
    }
  }
  .items_pagination {
    .page_link.active_page {
      text-decoration: none !important;
      font-size: 1.3em;
      font-weight: bold;
    }
  }
}
.article-info-term {
  display: none;
}
.page-header {
  .highlight {
    font-family: $primary-font;
  }
}

.sharethis-inline-share-buttons {
  margin-top: 4rem;
  margin-bottom: 2rem;
}
