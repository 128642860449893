body {
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  color: $base-font-color;
  font-weight: $base-font-weight;
  background: $base-background-color;
}

main,
details {
  display: block;
}

p {
  margin: 0 0 0.5rem 0;
}

a {
  color: $white;
  background-color: transparent;
  text-decoration: none;
  @include transition($global-transition);
  &:hover {
    color: $medium-grey;
  }
}

b,
.b,
strong,
.strong {
  font-weight: 700;
}

em,
.em {
  font-style: italic;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

small,
.small {
  font-size: 0.8125rem;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}

img {
  border-style: none;
}

template,
[hidden] {
  display: none;
}

/* #Lists
================================================== */
ul,
ol {
  margin-bottom: 20px;
  color: $white;
  margin-left: 25px;
}
ul {
  list-style: disc;
  &.square {
    list-style: square outside;
  }
  &.circle {
    list-style: circle outside;
  }
  &.disc {
    list-style: disc outside;
  }
}
ol {
  list-style: decimal;
}
ol,
ul.square,
ul.circle,
ul.disc {
  margin-left: 30px;
}
ul ul,
ul ol,
ol ol,
ol ul {
  margin: 4px 0 5px 30px;
  font-size: 90%;
}
