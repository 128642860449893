/* Main
================================================== */
main {
  width: 100%;
  float: left;
  position: relative;
  background-color: $dim_grey;
  @include bp(xxlarge) {
    .full-height-column {
      display: table;
    }
    .v-align {
      //@extend %va;
      display: table-cell;
      vertical-align: middle;
      float: none;
    }
  }
  .cover {
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
  }
  .item-page {
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.intro,
.second-section {
  min-height: 450px;
  @include bp(large) {
    min-height: 800px;
    //min-height: 1000px;
  }
  /*@include bp(xxlarge) {
    	min-height: 800px;
    	min-height: 1200px;
    }*/
}

.full-height-column {
  @include bp_max(xlarge) {
    height: auto !important;
  }
}

.circle-button,
.small-button,
#form12 .button-submit,
.ba-form-submit-btn {
  @extend %inline-block;
  .circle-icon,
  .icon {
    position: relative;
    z-index: 1;
    display: block;
    float: left;
    width: 50px;
    height: 50px;
    top: 9px;
    cursor: pointer;
    margin: 0;
    outline: none;
    background-color: $primary-colour;
    border-radius: 50%;
    @include transition(background-color 400ms ease-in-out);
    i {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: $secondary-colour;
      font-weight: bold;
      font-size: 2em;
      padding: 1px 0 0 0;
    }
  }
  &:hover {
    .circle-icon {
      background-color: $tertiary-colour;
    }
  }
  p {
    @extend %inline-block;
    font-family: $secondary-font;
    font-size: 1.4em;
    margin: 20px 0 0 10px;
    @include transition(color 400ms ease-in-out);
  }
}
.small-button {
  .icon {
    width: 40px;
    height: 40px;
    top: 12px;
    background-color: transparent;
  }
  .title {
    margin-left: 0;
    font-size: 1.2em;
  }
}

.label-warning {
  display: none;
}
