button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

/* Balbooa Forms Component 
================================================== */
.com-baforms-wrapper {
  .ba-form-page {
    .ba-form-field-item {
      margin: 0 0 1rem 0;
    }
    fieldset {
      padding: 0;
      border: none;
    }
    .ba-form-field-item
      .ba-form-products-cart
      .ba-form-product-quantity-cell
      input,
    .ba-form-page-navigation-title,
    .upload-file-btn,
    .upload-file-input,
    .ba-field-container select,
    .ba-field-container textarea[readonly],
    .ba-field-container textarea,
    .ba-field-container input[type="text"],
    .ba-field-container input[type="number"],
    .ba-field-container input[type="password"],
    .ba-field-container input[type="email"],
    .ba-field-container select option,
    .ba-form-field-item .ba-form-checkbox-wrapper .ba-checkbox-title {
      font-weight: normal;
      font-size: 1rem;
      color: $base-font-color;
    }
    .ba-form-acceptance-field .ba-field-container {
      margin-top: 2rem;
    }
    .ba-form-submit-wrapper .ba-form-submit-btn-wrapper {
      width: auto !important;
    }
    .ba-form-submit-field {
      margin-bottom: 0 !important;
    }
    .ba-form-submit-btn {
      display: flex;
      align-items: center;
      margin-top: 2rem;
      margin-bottom: 0;
    }
  }
}

.com-baforms-wrapper {
  .ba-form-page {
    padding: 0 !important;
    background: transparent !important;
  }
  .ba-field-label-wrapper {
    display: none;
  }
  .field-underline {
    .ba-field-container {
      &:after {
        content: "";
        position: absolute;
        right: 0;
        bottom: -5px;
        left: 0;
        width: 80%;
        height: 2px;
        margin: auto;
        background: linear-gradient(
          to right,
          rgba(0, 0, 0, 0) 0%,
          rgba(175, 109, 17, 1) 50%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }
  }
  .ba-field-container input[type="email"] {
    position: relative;
    font-size: 1.4rem !important;
    font-weight: 200;
    font-style: normal;
    color: $white !important;
    text-transform: uppercase;
    text-align: center;
    font-family: $tertiary-font;
    background: transparent !important;
    -webkit-backface-visibility: hidden;
    -webkit-appearance: none;
    @include bp(large) {
      font-size: 1.8rem;
    }
    &::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: $white;
      font-size: 1.4rem;
      @include bp(large) {
        font-size: 1.8rem;
      }
    }
    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: $white;
      opacity: 1;
      font-size: 1.4rem;
      @include bp(large) {
        font-size: 1.8rem;
      }
    }
    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: $white;
      opacity: 1;
      font-size: 1.4rem;
      @include bp(large) {
        font-size: 1.8rem;
      }
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $white;
      font-size: 1.4rem;
      @include bp(large) {
        font-size: 1.8rem;
      }
    }
    &:not([readonly]):focus {
      background: transparent !important;
    }
  }
  .ba-form-acceptance-html {
    font-size: 0.8rem !important;
  }
  .ba-form-acceptance-field {
    display: flex;
    justify-content: center;
    margin: 0 !important;
    .ba-field-container {
      width: auto !important;
      margin: 0 !important;
    }
  }
  .ba-form-submit-btn-wrapper {
    justify-content: center !important;
  }
  .ba-form-acceptance-html {
    color: $white !important;
  }
  .ba-form-submit-btn {
    padding: 1rem 0 0 0 !important;
    background: transparent !important;
    .circle-icon {
      top: auto;
      margin-right: 0.5rem;
      background-color: $light-gold;
    }
    &:hover {
      .circle-icon {
        background-color: $secondary-colour;
      }
    }
    i {
      margin: 0 !important;
      color: $white;
      font-size: 1.8rem !important;
    }
  }
  .ba-form-submit-title {
    font-family: $secondary-font;
    color: $white;
    text-transform: uppercase;
    font-size: 1.4rem;
  }
}
